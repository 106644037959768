.product-container {
    position: relative;
    width: 250px;
    height: 100%;
    min-height: 400px; /* Задаване на минимална височина за еднаква височина */
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    transition: transform 0.3s ease-in-out;
    margin-bottom: 5px;
}

.div_new_offer > h1{
    margin-top: 5px;
}

.product-container:hover {
    transform: translateY(-5px);
}

.product-image {
    width: 100%;
    height: 300px; /* Фиксирана височина за всички изображения */
    object-fit: cover; /* Гарантира, че изображението запълва контейнера без изрязване */
    border-radius: 10px;
}

.product-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.6);
    padding: 10px;
    border-radius: 50px;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.product-container:hover .product-overlay {
    opacity: 1;
}

.product-button {
    background: #f8b400;
    border: none;
    padding: 10px;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background 0.3s ease;
}

.product-button:hover {
    background: #222;
    color: white;
}

.product-label {
    position: absolute;
    top: 10px;
    left: 10px;
    background: red;
    color: white;
    padding: 5px 10px;
    font-size: 12px;
    font-weight: bold;
    border-radius: 5px;
}

.product-title {
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    color: #222;
    margin-top: 10px;
}

.product-price {
    font-size: 14px;
    color: #444;
    text-align: center;
}

.product-slider {
    width: 100%;
    margin: 0 auto;
    padding: 20px;
    overflow: hidden;
    position: relative;
}

.slick-slide {
    display: flex;
    justify-content: center;
    padding: 0 5px; /* Намалено разстояние между продуктите */
}


.slick-prev, .slick-next {
    color: #f8b400 !important;
    z-index: 10;
}

.slick-prev:hover, .slick-next:hover {
    color: #222 !important;
}

/* Премахва точките на долната навигация */
.slick-dots {
    display: none !important;
}


/* Скрива хоризонталния скрол */
body {
    overflow-x: hidden;
}

/* Добавя плавно превъртане при скролване */
.product-slider:hover {
    scroll-behavior: smooth;
}

.slick-list {
    cursor: grab;
}


.slick-list:active {
    cursor: grabbing;
}

.div_new_offer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
}

.view-all-button {
    display: flex;
    align-items: center;
}

.view-all-button a {
    text-decoration: none;
    color: #222;
    font-weight: bold;
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 8px 15px;
    border: 2px solid #222;
    border-radius: 5px;
    transition: all 0.3s ease;
    font-size: 14px;
}

.view-all-button a:hover {
    background: #222;
    color: white;
}

.view-all-button a::after {
    content: '→';
    font-size: 18px;
    transition: transform 0.3s ease-in-out;
}

.view-all-button a:hover::after {
    transform: translateX(5px);
}


@media (max-width: 500px) {
    /* .product-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 10px;
        justify-content: center;
    } */

    .product-slider .slick-slide {
        padding: 0 10px; /* Увеличено разстояние за мобилни устройства */
    }
    .product-container {
        width: 90%; /* Продуктите да заемат повече от екрана */
        min-height: 350px; /* Намалена минимална височина за мобилна версия */
    }
    .product-image {
        height: 250px; /* Адаптиране на височината на изображението за мобилни устройства */
    }

    .div_new_offer h1 {
        font-size: 18px;
        text-align: center;
    }

    .div_new_offer {
        flex-direction: column;
        text-align: center;
        align-items: center;
    }
    
    .view-all-button {
        margin-top: 10px;
    }

    .slick-slide {
        padding: 0 1px;
    }

    .slick-slide {
        padding: 0;
    }
}
