.features-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px;
    padding: 50px 20px;
    background: #f9f9f9;
}

.feature-item {
    text-align: center;
    max-width: 220px;
}

.feature-icon {
    font-size: 50px;
    color: #333;
    margin-bottom: 15px;
}

h3 {
    font-size: 18px;
    font-weight: bold;
    color: #222;
    margin-bottom: 8px;
}

p {
    font-size: 14px;
    color: #555;
}

@media (max-width: 768px) {
    .features-container {
        flex-direction: column;
        gap: 20px;
    }
}
