footer {
  width: 100%;
  background: #333;
  color: #fff;
  padding: 40px 0;
  font-family: 'Poppins', sans-serif;
}

footer .content {
  max-width: 1200px;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 30px;
}

footer .content .box {
  flex: 1;
  min-width: 280px;
  text-align: center;
}

footer .content .topic {
  font-size: 20px;
  font-weight: 600;
  color: #f8b400;
  margin-bottom: 10px;
  text-transform: uppercase;
}

footer .content p, footer .content a {
  color: #ddd;
  font-size: 14px;
  line-height: 1.6;
  text-decoration: none;
  transition: 0.3s;
}

footer .content a:hover {
  color: #f8b400;
}

footer .content .media-icons a {
  font-size: 18px;
  height: 40px;
  width: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 5px;
  background: #444;
  border-radius: 50%;
  transition: 0.3s;
}

footer .content .media-icons a:hover {
  background: #f8b400;
  color: #fff;
}

footer .subscription-form {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}

footer .subscription-form input[type="text"] {
  flex: 1;
  max-width: 250px;
  padding: 10px;
  border: 1px solid #f8b400;
  border-radius: 5px;
  background: #444;
  color: #fff;
}

footer .subscription-form input[type="submit"] {
  padding: 10px 15px;
  border: none;
  background: #f8b400;
  color: #333;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
}

footer .subscription-form input[type="submit"]:hover {
  background: #fff;
  color: #f8b400;
}

footer .bottom {
  text-align: center;
  font-size: 12px;
  color: #aaa;
  padding-top: 20px;
}

.media-icons {
  display: flex !important;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.media-icons a {
  display: flex !important;
  visibility: visible !important;
  opacity: 1 !important;
  font-size: 20px;
  background: #444;
  padding: 10px;
  border-radius: 50%;
  transition: all 0.3s ease;
}

.media-icons a:hover {
  background: #f8b400;
  color: white;
}


@media (max-width: 768px) {
  footer .content {
      flex-direction: column;
      align-items: center;
  }
  footer .content .box {
      text-align: center;
  }
  footer .subscription-form {
      flex-direction: column;
      align-items: center;
  }
  footer .subscription-form input[type="text"],
  footer .subscription-form input[type="submit"] {
      width: 100%;
      max-width: 300px;
  }
}