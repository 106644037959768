/* Подобряване на стила за по-модерен вид */
.cart {
    position: relative;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    overflow: hidden;
    transition: transform 0.3s ease-in-out;
    padding: 10px;
}

.cart:hover {
    transform: scale(1.02);
}

.cart > img {
    width: 200px;
    height: auto;
    display: block;
    margin: 0 auto;
    border-bottom: 1px solid #eee;
}

.amount span {
    color: #e74c3c;
    font-size: 18px;
    font-weight: bold;
    padding: 0 20px;
}

.amount button {
    width: 40px;
    height: 40px;
    border: none;
    background-color: #3498db;
    color: white;
    font-size: 18px;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s;
}

.amount button:hover {
    background-color: #2980b9;
}

.color {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 2px solid #444;
    cursor: pointer;
    display: inline-block;
    transition: transform 0.3s;
}

.color:hover {
    transform: scale(1.1);
}

form {
    width: 100%;
    text-align: center;
    padding: 10px 0;
}

.input_buy {
    width: 50%;
    padding: 12px;
    text-transform: uppercase;
    background-color: #2ecc71;
    border: none;
    color: white;
    font-weight: 700;
    letter-spacing: 1px;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s;
}

.input_buy:hover {
    background-color: #27ae60;
}

.delete {
    position: absolute;
    top: 10px;
    right: 10px;
    color: #e74c3c;
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
    transition: color 0.3s;
}

.delete:hover {
    color: #c0392b;
}

.total {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    background-color: #f8f9fa;
    border-radius: 5px;
}

.total h3 {
    color: #e74c3c;
    font-size: 20px;
}

/* Подобряване на секцията за адрес и бутони */
.containerChoice {
    display: flex;
    justify-content: center;
    gap: 20px;
    padding: 15px 0;
}

.containerChoice div {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 18px;
    font-weight: bold;
}

.containerChoice input[type="checkbox"] {
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.inputBox input,
.inputBox textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
}

.inputBox input:focus,
.inputBox textarea:focus {
    border-color: #3498db;
    outline: none;
}

.inputBox input[type="submit"] {
    background-color: #3498db;
    color: white;
    cursor: pointer;
    transition: background 0.3s;
}

.inputBox input[type="submit"]:hover {
    background-color: #2980b9;
}

/* Стилизация на формуляра за доставка */
.register_page {
    max-width: 500px;
    margin: 20px auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.register_page label {
    font-size: 16px;
    font-weight: 600;
    color: #2c3e50;
    margin-bottom: 5px;
}

.register_page input {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    transition: border-color 0.3s, box-shadow 0.3s;
}

.register_page input:focus {
    border-color: #3498db;
    box-shadow: 0 0 5px rgba(52, 152, 219, 0.5);
    outline: none;
}

h2 {
    text-align: center;
    color: #34495e;
    font-size: 24px;
    margin-bottom: 15px;
}

/* Адаптивност за мобилни устройства */
@media (max-width: 600px) {
    .register_page {
        max-width: 90%;
        padding: 15px;
    }
}


@media (max-width: 720px) {
    .containerModalTwo {
        width: 95%;
        height: auto;
        background-color: white;
        border: 1px solid black;
    }
    .input_buy {
        width: 100%;
        height: 50px;
    }
}
