.product_card{
    max-width: 250px;
    min-width: 250px;
    border-radius: 2px;
    overflow: hidden;
    height: auto;
    padding: 15px;
    box-shadow: 0 0 15px #ddd;
    margin: 10px 0;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.product_card img{
    width: 100%;
    height: 300px;
    display: block;
    object-fit: contain;
}

.sold_out_label {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-20deg);
    background: rgba(255, 0, 0, 0.5);
    color: white;
    padding: 10px 20px;
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.product_box {
    width: 100%;
    text-align: center;
    padding: 10px 0;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.product_box h2{
    width: 100%;
    text-transform: capitalize;
    cursor: pointer;
    color: #323232;
    display: block;
    font-size: 18px;
    text-align: center;
    padding: 5px 0;
    white-space: normal;
    overflow: visible;
    word-wrap: break-word;
    line-height: 1.3;
    min-height: 45px;
}

.product_card span{
    color: crimson;
    font-size: 18px;
    font-weight: bold;
}

.product_card .sale {
    text-decoration: line-through; 
    float: right;
    color: black;
}

.product_card .sale_procent{
    position: absolute;
    top: 10px;
    left: 10px;
    border: 1px solid #CC2128;
    font-size: 15px;
    font-weight: bold;
    color: white;
    background-color: #CC2128;
    padding: 5px;
    border-radius: 10%;
}

.product_box p{
    width: 100%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    height: 70px;
    overflow: hidden;
    color: #323232;
}

.row_btn{
    width: 100%;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
}

.row_btn a{
    width: 50%;
    text-align: center;
    text-transform: uppercase;
    color: white;
    font-weight: 600;
    letter-spacing: 2px;
    padding: 6px;
}

#btn_buy{
    background: #555;
    margin-right: 5px;
}

#btn_view{
    background: teal;
    margin-left: 5px;
    width: 100%;
}

.product_card input{
    position: absolute;
    width: 25px;
    height: 25px;
}

@media (max-width: 500px){ 
    .product_card{
        max-width: 320px;
        min-width: 320px;
        height: auto;
    }

    .product_card img{
        width: 100%;
        height: 400px;
        display: block;
        object-fit: cover;
    }

    .product_box p{
        width: 100%;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        height: 35px;
        overflow: hidden;
        color: #323232;
    }

    .product_card .sale_procent{
        position: absolute;
        border: 1px solid #CC2128;
        font-size: 12px;
        font-weight: bold;
        color: white;
        background-color: #CC2128;
        padding: 5px;
        border-radius: 10%;
    }
}