.products {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    justify-items: center;
    margin: 20px 0;
}

.heading_product {
    margin: 20px;
    margin-top: 5.1%;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
}

/* ----------- Product Item ----------------- */
@import url("../utils/ProductItem/productItem.css");

.delete-all {
    text-align: right;
    margin: 20px;
}

.delete-all input {
    height: 25px;
    width: 25px;
    transform: translateY(5px);
    margin: 0 15px;
}

.delete-all span {
    text-transform: uppercase;
    color: rgb(3, 165, 206);
    letter-spacing: 1.3px;
}

.delete-all button {
    border: 1px solid crimson;
    padding: 10px 25px;
    color: crimson;
    text-transform: uppercase;
}

/* ------------ Filters Menu ----------------- */
.filter_menu {
    width: 100%;
    min-height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 15px;
    margin: 20px 0;
    background: #f8f8f8;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.filter_menu .row {
    display: flex;
    align-items: center;
    gap: 10px;
}

.filter_menu span {
    font-weight: bold;
    color: #333;
}

.filter_menu select,
.filter_menu input {
    outline: none;
    height: 45px;
    padding: 0 10px;
    border-radius: 8px;
    border: 1px solid #ccc;
    font-size: 16px;
    background: white;
    transition: all 0.3s ease-in-out;
}

.filter_menu select:hover,
.filter_menu input:hover {
    border-color: #777;
}

.filter_menu select:focus,
.filter_menu input:focus {
    border-color: #ca0000;
    box-shadow: 0 0 5px rgba(202, 0, 0, 0.5);
}

.filter_menu input {
    flex: 1;
    width: 250px;
}

/* -------------------- Load More --------------- */
.load_more {
    text-align: center;
}

.load_more button {
    padding: 10px 25px;
    margin-bottom: 20px;
    border: 1px solid #555;
    text-transform: capitalize;
    background-color: #CA0000;
    font-weight: bold;
    color: white;
    border: none
}

@media (max-width: 500px) {
    .products {
        grid-template-columns: repeat(2, 1fr);
    }
    .product_card {
        max-width: 160px;
        min-width: 140px;
    }
    .product_card img {
        height: 160px;
        object-fit: cover;
    }
    .product_box h2 {
        font-size: 12px;
    }
    .product_card span {
        font-size: 12px;
    }
    .sold_out_label {
        position: absolute;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(-15deg);
        background: rgba(255, 0, 0, 0.7);
        color: white;
        padding: 5px 10px;
        font-size: 10px;
        font-weight: bold;
        text-transform: uppercase;
        border-radius: 3px;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    }
    .filter_menu {
        flex-direction: column;
        align-items: center;
        padding: 20px;
    }

    .filter_menu .row {
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .filter_menu input,
    .filter_menu select {
        width: 100%;
        max-width: 400px;
        min-height: 40px;
        }
    .heading_product {
        text-align: center;
        font-size: 25px;
    }

}